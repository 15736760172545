import React from "react";
// , { useMemo }

import {
	Flex,
	Spacer,
	Text,
	Heading,
	Skeleton,
	VStack,
	Container,
	HStack,
	Stack,
	Avatar,
	AvatarBadge,
	Tabs,
	Tab,
	TabList,
	Button,
} from "@chakra-ui/react";

// import MainContent from "../../core/ReactTable/layout/MainContent";
import MainContent from "../App/components/MainContent";

import PayrollAPI from "../../APIs/PayrollAPI";
import {
	EmployeeHistoryData,
	EmployeeHistoryRow,
	AccessMap,
} from "./classes/MeritIncreasesClassesLegacy";
import { JOYRIDE_PROPOSEDINCREASES } from "../../joyride";

import { portalUser } from "../../App";
import { formatValue } from "../../helperFunctions";

import { log } from "../../helperFunctions";

import MeritIncreasesHistoryTable from "./components/MeritIncreasesHistoryTable";
import MeritIncreasesRegionSummariesPage from "./pages/MeritIncreasesRegionSummariesPage";

import MeritIncreasesDeptSecurityHeader from "./components/MeritIncreasesDeptSecurityHeader";
import MeritIncreasesDeptSecurityPage from "./components/MeritIncreasesDeptSecurityPage";
import MeritIncreasesDeptSecurityDrawer from "./components/MeritIncreasesDeptSecurityDrawer";

export default class MeritIncreasesUnlocked extends React.Component {
	constructor(props) {
		super(props);

		let tabs = ["Proposed Increases"];

		let isFullExecUser = false;
		if (portalUser.user?.isFullExecUserYN === "Y") {
			tabs.push("Region Totals");
			tabs.push("Merit Security");
			// 	isFullExecUser = true;
			// 	tabs = ["Salaries & Bonuses", "Region Summaries", "Merit Security"];
			// } else {
			// 	tabs = ["Salaries & Bonuses"];
		}

		this.state = {
			tabVal: this.props.tabVal ?? 0,
			tabPanelVal: 0,

			isDisabled: false,

			payrollAPI: new PayrollAPI(),

			isFullExecUser: isFullExecUser,
			tabs: tabs,

			userAccess: null,
			userAccessRow: null,

			proposalYear: formatValue(new Date(), 0, "moment").year() + 1,
			employeeHistory: null,
			regionArr: [
				{
					regionID: -1,
					region: "All Regions",
					regionLabel: "All",
					color: "teal.600",
					showRegion: true,
				},
				{
					regionID: 0,
					region: "SoCal",
					regionLabel: "SoCal",
					color: "green.600",
					showRegion: true,
				},
				{
					regionID: 1,
					region: "NorCal",
					regionLabel: "NorCal",
					color: "orange.600",
					showRegion: true,
				},
				{
					regionID: 3,
					region: "Hawaii",
					regionLabel: "Hawaii",
					color: "blue.600",
					showRegion: true,
				},
			],
			deptArr: null,
			deptGroupMap: [
				{
					deptGroup: "Loading",
					deptGroupDesc: "Loading Departments...",
					deptGroupIcon: "fas fa-spinner fa-spin",
					deptGroupColor: "gray",
					rows: [],
				},
			],
			deptTypeArr: [
				{ deptType: "G&A", description: "G&A", icon: "SoCal" },
				{
					deptType: "Indirect",
					description: "Job Charge & Indirects",
					icon: "All Regions",
				},
				{
					deptType: "Recharge",
					description: "PMs & Engineers",
					icon: "NorCal",
				},
			],
			leaderAccess: null,
			defaultOpen: true,
			showDetail: false,

			selectedEmployee: null,
			selectedLeader: null,
			selectedAccessLevel: null,

			levelsMap: null,
			levelsMapFiltered: null,

			runJoyride: false,
			joyrideSteps: JOYRIDE_PROPOSEDINCREASES,
		};

		this.handleTabChange = this.handleTabChange.bind(this);
		this.scrollTop = this.scrollTop.bind(this);
		this.handleTabPanelChange = this.handleTabPanelChange.bind(this);
		this.closeForm = this.closeForm.bind(this);
		this.startJoyride = this.startJoyride.bind(this);
		this.joyrideCallback = this.joyrideCallback.bind(this);

		this.init = this.init.bind(this);
		this.getLeaderAccess = this.getLeaderAccess.bind(this);

		this.getUserAccessRow = this.getUserAccessRow.bind(this);

		this.selectEmployee = this.selectEmployee.bind(this);
		this.closeEmployee = this.closeEmployee.bind(this);

		this.selectAccess = this.selectAccess.bind(this);
		this.selectLeader = this.selectLeader.bind(this);
		this.closeLeader = this.closeLeader.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	async init() {
		let employeeHistoryData =
			await this.state.payrollAPI.GetEmployeeHistory(
				portalUser.user.employeeUID
			);

		if (employeeHistoryData.status === 200) {
			let employeeHistory = new EmployeeHistoryData(
				employeeHistoryData.value
			);

			let proposalYear = formatValue(new Date(), 0, "moment").year() + 1;
			let regionArr = this.state.regionArr;
			let deptArr = employeeHistory.createDeptArr(regionArr);
			employeeHistory = employeeHistory.addDepartments(deptArr);
			this.setState(
				{
					proposalYear: proposalYear,
					employeeHistory: employeeHistory,
					deptArr: deptArr,
				},
				() => {
					this.getLeaderAccess(employeeHistory);
				}
			);
		}
	}

	async getLeaderAccess(employeeHistory) {
		let leaderAccess = this.state.leaderAccess;

		if (leaderAccess === null || leaderAccess?.length === 0) {
			let leaderData = await this.state.payrollAPI.GetDepartmentLeaders(
				portalUser.user?.employeeUID
			);
			if (leaderData.status === 200) {
				let leaderAccess = new AccessMap(leaderData.value);
				leaderAccess.getAccessLevel();

				let userAccess = leaderAccess.getEmployeeAccess(
					portalUser.user?.employeeUID
				);
				employeeHistory =
					employeeHistory.applyLeaderAccess(leaderAccess);
				let levelsMap = leaderAccess.createLevelsMap();
				levelsMap.addEmployeeHistory(employeeHistory);

				let deptByRegionMap =
					employeeHistory.createDepartmentMap("deptByRegion");
				deptByRegionMap = deptByRegionMap.getSubTotals();

				let deptGroupMap =
					deptByRegionMap.createDepartmentMap("deptGroup");
				deptGroupMap.getTotals();
				deptGroupMap.push(deptGroupMap.shift());

				this.setState({
					employeeHistory: employeeHistory,
					leaderAccess: leaderAccess,
					userAccess: userAccess,
					deptGroupMap: deptGroupMap,
					levelsMap: levelsMap,
				});
			}
		}
	}

	getUserAccessRow() {
		let deptGroupMap = this.state.deptGroupMap;
		let tabPanelVal = this.state.tabPanelVal;
		let userAccess = this.state.userAccess;
		let deptGroup = deptGroupMap[tabPanelVal];

		let execAccessRow = userAccess?.filter(
			(data) => data?.deptGroupID === 1
		);
		let groupAccessRow = userAccess?.filter(
			(data) => data?.deptGroupID === deptGroup?.deptGroupID
		);

		let userAccessRow = null;
		if (execAccessRow?.length > 0) {
			userAccessRow = execAccessRow[0];
		} else if (groupAccessRow?.length > 0) {
			userAccessRow = groupAccessRow[0];
		}

		this.setState({
			userAccessRow: userAccessRow,
		});
	}

	selectEmployee(employeeHistoryRow) {
		let employeeHistory = this.state.employeeHistory;

		if (employeeHistoryRow !== null) {
			let parentDeptID = Number(employeeHistoryRow?.parentDeptID);
			let regionID = Number(employeeHistoryRow?.regionID);

			let leaderAccessfiltered = this.state.leaderAccess;
			leaderAccessfiltered = leaderAccessfiltered.filter(
				(data) =>
					data?.parentDeptID === parentDeptID ||
					data?.parentDeptID === 1
			);
			leaderAccessfiltered = leaderAccessfiltered.filter(
				(data) => data?.regionID === regionID || data?.regionID === -1
			);
			leaderAccessfiltered.getAccessLevel();
			let levelsMapfiltered = leaderAccessfiltered.createLevelsMap();
			levelsMapfiltered.addEmployeeHistory(employeeHistory);

			this.setState({
				selectedEmployee: employeeHistoryRow,
				showDetail: true,
				defaultOpen: true,
				levelsMapFiltered: levelsMapfiltered,
			});
		} else {
			this.closeEmployee();
		}
	}

	closeEmployee() {
		this.setState({
			selectedEmployee: null,
			showDetail: false,
			levelsMapFiltered: null,
		});
	}

	selectAccess(levelsRow = null) {
		let selectedAccess;
		if (levelsRow !== null) {
			selectedAccess = levelsRow;
		}
		this.setState({
			selectedAccess: selectedAccess,
		});
	}

	selectLeader(employeeHistoryRow = null) {
		let selectedLeader;
		if (employeeHistoryRow === null) {
			selectedLeader = new EmployeeHistoryRow();
		} else {
			selectedLeader = employeeHistoryRow;
		}

		this.setState({
			selectedLeader: selectedLeader,
		});
	}

	closeLeader() {
		this.setState({
			selectedAccess: null,
			selectedLeader: null,
		});
	}

	handleTabChange(tabVal) {
		this.setState({ tabVal: tabVal });
	}

	scrollTop() {
		document.body.scrollTop = document.documentElement.scrollTop = 0;
	}

	handleTabPanelChange(tabPanelVal) {
		this.scrollTop();

		this.setState(
			{
				tabPanelVal: tabPanelVal,
			},
			() => {
				this.getUserAccessRow();
			}
		);
	}

	closeForm() {
		this.handleTabPanelChange(0);
	}

	startJoyride() {
		this.setState({ runJoyride: true });
	}

	joyrideCallback(joyrideState) {
		if (
			joyrideState.action === "reset" ||
			joyrideState.action === "close" ||
			joyrideState.action === "skip" ||
			joyrideState.action === "last"
		) {
			this.setState({ runJoyride: false });
		}
	}

	render() {
		let title = "Proposed Increases " + this.state.proposalYear;

		let levelMap =
			this.state.levelsMapFiltered !== null
				? this.state.levelsMapFiltered
				: this.state.levelsMap;
		levelMap = levelMap?.filter((data) => data?.accessLevel !== 0);
		levelMap = levelMap?.filter((data) => data?.accessLevel !== 5);
		levelMap = levelMap?.filter((data) => data?.showLevel === true);

		log("levelMap", levelMap);

		return (
			<MainContent
				onTabChange={this.handleTabChange}
				tabVal={this.state.tabVal}
				title={title}
				tabs={this.state.tabs}
				joyrideID="joyrideStep7"
				{...this.props}
				runJoyride={this.state.runJoyride}
				joyrideSteps={this.state.joyrideSteps}
				joyrideCallback={this.joyrideCallback}
				startJoyride={this.startJoyride}
			>
				<Flex
					bg="blackAlpha.500"
					direction="column"
					flex={1}
					justify="flex-start"
					w="full"
					h="full"
					p={4}
				>
					<Container maxW="full" px="0">
						{this.state.tabVal === 0 && (
							<Tabs
								colorScheme="teal"
								index={this.state.tabPanelVal}
								onChange={this.handleTabPanelChange}
							>
								<TabList
									mx="4"
									color="gray.600"
									borderBottomColor="whiteAlpha.500"
									id={"joyrideStep6"}
								>
									{this.state.deptGroupMap?.map((tab, i) => (
										<Tab
											key={i}
											_selected={{
												color: "teal.600",
												bg: "whiteAlpha.500",
												borderTopRadius: "5",
												borderBottom: "3px solid",
											}}
											_hover={{
												color: "teal.500",
												bg: "whiteAlpha.500",
												borderTopRadius: "5",
												borderBottom: "3px solid",
												borderBottomColor: "teal.600",
											}}
										>
											<Heading
												size="sm"
												textTransform="uppercase"
											>
												{tab.deptGroup}
											</Heading>
										</Tab>
									))}
								</TabList>

								<VStack
									spacing={8}
									w="full"
									px={{ base: "0", lg: "4" }}
									py={{ base: "0", lg: "2" }}
								>
									<Container
										maxW="full"
										bg="white"
										rounded="5"
										shadow="xl"
										minH="6xl"
										p="8"
										key={
											this.state.deptGroupMap[
												this.state.tabPanelVal
											]?.deptGroup + "-page"
										}
										position="relative"
									>
										{this.state.tabVal === 0 && (
											<Button
												position="absolute"
												top={0}
												right={0}
												m={4}
												fontWeight="bold"
												letterSpacing={1}
												textTransform="uppercase"
												colorScheme="blue"
												size="sm"
												onClick={() =>
													this.startJoyride()
												}
											>
												<HStack align="center">
													<i className="fas fa-question-circle fa-lg"></i>
													<Text>Tutorial</Text>
												</HStack>
											</Button>
										)}

										<Flex
											pt="2"
											pb="4"
											flex={1}
											justify="space-between"
											align="center"
											w="full"
											key={
												"Avatar-AccessLevel-" +
												this.state.userAccessRow
													?.accessLevel +
												"-deptGroup-" +
												this.state.deptGroupMap[
													this.state.tabPanelVal
												]?.deptGroup
											}
										>
											<HStack
												spacing={6}
												align="center"
												isTruncated
											>
												<Avatar
													mb={2}
													bg="gray.400"
													size="lg"
													shadow="md"
													color="white"
													icon={
														<i
															className={
																"fas " +
																this.state
																	.deptGroupMap[
																	this.state
																		.tabPanelVal
																]
																	?.deptGroupIcon +
																" fa-lg "
															}
														></i>
													}
												>
													<AvatarBadge
														border="0.125em solid"
														boxSize="1.35em"
														bg="gray.500"
														shadow="sm"
														color="white"
													>
														<i
															className={
																"fas " +
																(this.state
																	.userAccessRow
																	?.accessLevelIcon ??
																	"fa-cog fa-spin") +
																" fa-xs fa-fw"
															}
														></i>
													</AvatarBadge>
												</Avatar>

												<Stack isTruncated>
													<Heading
														isTruncated
														textTransform="uppercase"
														letterSpacing={3}
														size="xl"
														color="gray.500"
													>
														{
															this.state
																.deptGroupMap[
																this.state
																	.tabPanelVal
															]?.deptGroupDesc
														}
													</Heading>
													<Heading
														size="sm"
														color="gray.400"
														textTransform="uppercase"
														letterSpacing={3}
													>
														{this.state
															.userAccessRow
															?.accessLevelDesc ??
															"Thank you for your patience"}
													</Heading>
												</Stack>
											</HStack>

											<Spacer />
										</Flex>

										<VStack
											spacing="6"
											w="full"
											px="4"
											pt={
												this.state.deptGroupMap[
													this.state.tabPanelVal
												].rows?.length > 0
													? "0"
													: "6"
											}
										>
											<Skeleton
												isLoaded={
													this.state.deptGroupMap[
														this.state.tabPanelVal
													].rows?.length > 0 &&
													Boolean(
														this.state.userAccess
													)
												}
												w="full"
												rounded="5"
												minH="4xl"
											>
												{Boolean(
													this.state.deptGroupMap[
														this.state.tabPanelVal
													].rows?.length > 0
												) &&
													Boolean(
														this.state.userAccess
													) && (
														<Stack
															w="full"
															spacing={6}
														>
															{this.state.deptGroupMap[
																this.state
																	.tabPanelVal
															].rows?.map(
																(row, i) => (
																	<MeritIncreasesHistoryTable
																		key={i}
																		joyrideID={
																			i ===
																			0
																				? "joyrideStep1"
																				: null
																		}
																		userAccess={
																			this
																				.state
																				.userAccess
																		}
																		row={
																			row
																		}
																		tabVal={
																			this
																				.state
																				.tabVal
																		}
																		isDisabled={
																			this
																				.state
																				.isDisabled
																		}
																		proposalYear={
																			this
																				.state
																				.proposalYear
																		}
																	/>
																)
															)}
														</Stack>
													)}
											</Skeleton>
										</VStack>
									</Container>
								</VStack>
							</Tabs>
						)}

						{/* REGION SUMMARIES */}
						{this.state.tabVal === 1 &&
							portalUser.user?.isFullExecUserYN === "Y" && (
								<MeritIncreasesRegionSummariesPage
									proposalYear={this.state.proposalYear}
									employeeHistory={this.state.employeeHistory}
									regionArr={this.state.regionArr}
									deptTypeArr={this.state.deptTypeArr}
								/>
							)}
						{/* SECURITY & ACCESS */}
						{this.state.tabVal === 2 &&
							portalUser.user?.isFullExecUserYN === "Y" && (
								<VStack
									spacing={8}
									w="full"
									px={{ base: "0", lg: "4" }}
									py={{ base: "0", lg: "2" }}
								>
									<Container
										key={"mfrPage1"}
										maxW="8xl"
										bg="white"
										rounded="5"
										shadow="xl"
										minH="7xl"
										p="8"
									>
										{this.state.selectedLeader && (
											<MeritIncreasesDeptSecurityDrawer
												regionArr={this.state.regionArr}
												deptArr={this.state.deptArr}
												employeeHistory={
													this.state.employeeHistory
												}
												selectedLeader={
													this.state.selectedLeader
												}
												levelsMap={this.state.levelsMap}
												onClose={this.closeLeader}
											/>
										)}
										<MeritIncreasesDeptSecurityHeader
											title={"Department Leader Access"}
											employeeHistory={
												this.state.employeeHistory
											}
											selectedEmployee={
												this.state.selectedEmployee
											}
											selectEmployee={this.selectEmployee}
											closeEmployee={this.closeEmployee}
											selectLeader={this.selectLeader}
											showAddressBar
										/>
										<Container maxW="container.xl">
											<Stack
												spacing="0"
												pt="2"
												w="full"
												align="flex-start"
											>
												{levelMap?.map((level, i) => {
													return (
														<MeritIncreasesDeptSecurityPage
															key={i}
															level={level}
															showDetail={
																this.state
																	.showDetail
															}
															defaultOpen={
																this.state
																	.defaultOpen
															}
															selectedEmployee={
																this.state
																	.selectedEmployee
															}
															selectLeader={
																this
																	.selectLeader
															}
															regionArr={
																this.state
																	.regionArr
															}
														/>
													);
												})}
											</Stack>
										</Container>
									</Container>
								</VStack>
							)}
					</Container>
				</Flex>
			</MainContent>
		);
	}
}

// function MeritIncreasesComponent(props) {
// 	return <Container></Container>;
// }
