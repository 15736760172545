import React from "react";
import {
	Flex,
	Stack,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from "@chakra-ui/react";

import VendorList from "./AvailableVendors/VendorList";
import RequirementsPage from "./Requirements/RequirementsPage";
import SpentToDatePage from "./SpentToDate/SpentToDatePage";

import MainContent from "../../../../legacy/App/components/MainContent";

export default function MFRDiversityPage(props) {
	return (
		<MainContent title={props.pageName} tabs={[]}>
			<Stack px="2">
				<Accordion defaultIndex={[0]} allowToggle>
					<AccordionItem>
						<AccordionButton bg="teal.300" _hover={{ bg: "teal.500" }}>
							<Flex
								fontWeight="semibold"
								flex="1"
								textAlign="left"
								textTransform="uppercase"
								paddingY={2}
							>
								Diversity Requirements
							</Flex>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel>
							<RequirementsPage />
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
				<Accordion allowToggle>
					<AccordionItem>
						<AccordionButton bg="teal.300" _hover={{ bg: "teal.500" }}>
							<Flex
								fontWeight="semibold"
								flex="1"
								textAlign="left"
								textTransform="uppercase"
								paddingY={2}
							>
								Available Vendors
							</Flex>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel>
							<VendorList />
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
				<Accordion defaultIndex={[0]} allowToggle>
					<AccordionItem>
						<AccordionButton bg="teal.300" _hover={{ bg: "teal.500" }}>
							<Flex
								fontWeight="semibold"
								flex="1"
								textAlign="left"
								textTransform="uppercase"
								paddingY={2}
							>
								Spent to Date
							</Flex>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel>
							<SpentToDatePage />
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</Stack>
		</MainContent>
	);
}
