import React, { useState } from "react";
import { Heading, Progress, HStack, Stack, Text, Flex, Button, Icon } from "@chakra-ui/react";
import { FaPlus, FaMinus } from "react-icons/fa";
import VendorList from "../AvailableVendors/VendorList";
import { formatValue, log } from "../../../../../helperFunctions";

export default function ProgressBar({ data }) {
	const [showVendors, setShowVendors] = useState(false);
	const percentage = (percent = false) =>
		percent
			? formatValue((data?.spentToDate / data?.totalRequired) * 100, 2, "percent")
			: (data?.spentToDate / data?.totalRequired) * 100;

	return (
		<Stack w="full" flex={1} spacing={3} direction="column">
			<Flex justify="space-between">
				<Heading color="teal.600" textTransform="uppercase" letterSpacing={1} size="md">
					{data.label}
				</Heading>
				<Text fontWeight="semibold" color="teal.600" letterSpacing={1}>
					{percentage(true)}
				</Text>
			</Flex>
			<HStack>
				<Progress
					rounded="md"
					h={6}
					colorScheme="teal"
					w="full"
					size="lg"
					hasStripe
					isAnimated
					bg="whiteAlpha.700"
					value={percentage()}
					borderWidth={1}
					borderColor="teal.500"
					sx={{
						"& > div:first-of-type": {
							transitionProperty: "width",
						},
					}}
				/>
			</HStack>
			<Flex justify="space-between">
				<Stack>
					<Flex fontSize="3xl">{percentage(true)}</Flex>
					<Flex fontSize="xl">Towards Goal</Flex>
				</Stack>
				<Stack>
					<Flex fontSize="3xl">{formatValue(data?.spentToDate, 2, "currency")}</Flex>
					<Flex fontSize="xl">Spent to Date</Flex>
				</Stack>
				<Stack>
					<Flex fontSize="3xl">{formatValue(data?.totalRequired, 2, "currency")}</Flex>
					<Flex fontSize="xl">Total Required</Flex>
				</Stack>
				<Stack>
					<Flex fontSize="3xl">{formatValue(data?.totalRequired - data?.spentToDate, 2, "currency")}</Flex>
					<Flex fontSize="xl">Total Remaining</Flex>
				</Stack>
			</Flex>
			<Flex justifyContent="flex-end" w="full" pt={4}>
				<Button
					variant="outline"
					onClick={() => setShowVendors(!showVendors)}
					color="gray.500"
					borderColor="teal.300"
					px={2}
					size="md"
					bg="whiteAlpha.800"
					shadow="md"
					textTransform="uppercase"
					_hover={{
						color: "teal.500",
						fontWeight: "bold",
						bg: "whiteAlpha.900",
						borderColor: "teal.500",
						borderWidth: 2,
					}}
					tabIndex={-1}
				>
					<HStack align="center" spacing={1}>
						<Icon as={showVendors ? FaMinus : FaPlus} fontSize="sm" />
						<Text lineHeight={1} fontSize="sm" letterSpacing={1} pr={1}>
							{showVendors ? "Hide Vendors" : "Show Vendors"}
						</Text>
					</HStack>
				</Button>
			</Flex>
			{showVendors && <VendorList isSpending={true} data={[]} />}
		</Stack>
	);
}
