import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Flex, Stack, useBoolean } from "@chakra-ui/react";
import { portalUser } from "../../../../../App";
import { convertArrayToMap, log } from "../../../../../helperFunctions";
import Section from "../../../../../core/Reports/components/Section";
import SectionObj from "../../../../../core/Reports/classes/Section";
import LoadingSection from "../../../../../core/ProgressIndicators/components/LoadingSection";
import DiversityVendorDrawer from "../../../../Purchasing/components/DiversityVendorDrawer";
import SectionTemplateVendors from "../../../../Purchasing/classes/SectionTemplateVendors";
import Operations from "../../../../Operations/classes/Operations";

export default function VendorList({ isSpending = false, data = [] }) {
	return (
		<Flex>
			<DiversityListPageTemplate isSpending={isSpending} data={data} />
		</Flex>
	);
}

function DiversityListPageTemplate({ isSpending, data }) {
	return (
		<Flex direction="column" flex={1} justify="flex-start" h="full" rounded={5}>
			<DiversityVendorPage isSpending={isSpending} data={data} />
		</Flex>
	);
}

const DiversityVendorPage = ({ isSpending, data }) => {
	const [selectedVendor, setSelectedVendor] = useState(null);
	const [updateTrigger, setUpdateTrigger] = useState(false);

	const [operations, setOperations] = useState(portalUser?.operations ?? null);
	//DATA IS LOADING FLAG AND CALLBACK FUNCTION
	const [isLoading, setIsLoading] = useBoolean(true);
	const setDataLoading = useCallback(
		async function (callback) {
			if (!isLoading) {
				setIsLoading.on();
			}
			callback();
		},
		[isLoading, setIsLoading]
	);

	//ON PAGE LOAD INIT Operations Data DATA
	const initOperationsData = useCallback(async () => {
		let msUserID = portalUser.user.msUserID ?? portalUser?.msUser?.id ?? null;
		let params = { msUserID };
		let newOperations = operations;
		if (Boolean(msUserID) && !Boolean(newOperations?.operationsAPI)) {
			newOperations = new Operations(portalUser?.operations);
			newOperations = await newOperations?.initOperations(params);
		}
		if (Boolean(newOperations?.operationsAPI)) {
			newOperations = await newOperations.getVendors(params);
			newOperations = await newOperations.getDiversityCerts(params);
		}

		setOperations(newOperations);
		setIsLoading.off();
	}, [operations, setIsLoading]);

	useEffect(() => {
		setDataLoading(() => initOperationsData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Update portalUser operations cache
	useMemo(() => {
		if (Boolean(operations) && !isLoading && !Boolean(isSpending)) {
			portalUser.operations = operations;
			log("portalUser.vendors has been Updated", portalUser);
		}
	}, [isLoading, isSpending, operations]);

	useEffect(() => {
		if (updateTrigger) {
			setUpdateTrigger(false);
		}
	}, [updateTrigger]);

	const saveVendor = async () => {
		setSelectedVendor(null);
	};

	const closeVendor = () => {
		setSelectedVendor(null);
	};

	const deleteVendorCert = useCallback(
		async (keyID) => {
			let newVendor = selectedVendor;
			await newVendor.deleteDiversityCert(keyID);
			setSelectedVendor(newVendor);
			setUpdateTrigger(true);
		},
		[setSelectedVendor, selectedVendor]
	);

	const selectVendor = useCallback(
		(vendor) => {
			if (Boolean(vendor?.apvmKeyID) && Boolean(operations)) {
				let certs = operations?.diversityCerts ?? [];
				certs = certs.filter((d) => d.vendorGroup === vendor?.vendorGroup) ?? [];
				let certMap = convertArrayToMap(certs, "vendorID", true);
				if (certMap[vendor?.vendorID]?.length > 0) {
					vendor.diversityCerts = certMap[vendor?.vendorID] ?? [];
				}
				setSelectedVendor(vendor);
			}
		},
		[operations, setSelectedVendor]
	);

	return (
		<Flex
			p={portalUser.styles?.pagePadding}
			bg={portalUser.styles?.pageBG}
			direction="column"
			flex={1}
			justify="flex-start"
			w="full"
			h="full"
		>
			<Stack
				align="flex-start"
				w="full"
				h="full"
				flex={1}
				spacing={portalUser.styles?.pageSpacing}
				_hover={{ cursor: "pointer" }}
			>
				{Boolean(selectedVendor) && (
					<DiversityVendorDrawer
						selectedVendor={selectedVendor}
						isOpen={Boolean(selectedVendor)}
						closeVendor={closeVendor}
						saveVendor={saveVendor}
						deleteVendorCert={deleteVendorCert}
					/>
				)}
				{isLoading ? (
					<LoadingSection />
				) : (
					<Section
						section={
							new SectionObj(
								new SectionTemplateVendors(
									"VendorDiversity",
									isSpending ? (data.length > 0 ? data : null) : operations?.vendors,
									true, //TODO: isLoading
									false,
									isSpending
								)
							)
						}
						selectedRow={selectedVendor}
						setSelectedRow={selectVendor}
					/>
				)}
			</Stack>
		</Flex>
	);
};
