import React, { useState, useEffect } from "react";
import { Flex, Stack, Button, Text, IconButton, Tooltip } from "@chakra-ui/react";
import DataInput from "../../../../../core/Inputs/data/DataInput";
import DIVERSITY_CERTS from "../../../../Purchasing/constants/DiversityCerts";
import Vendor from "../../../../Purchasing/classes/Vendor";
import DIVERSITY_SUB_TYPES from "../../../../Purchasing/constants/DiversitySubTypes";

export default function RequirementsPage() {
	const [jobRequirements, setJobRequirements] = useState([]);

	const vendor = new Vendor();
	useEffect(() => {
		vendor.sendExpirationEmail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Handles adding a new vertical row (AND operation)
	const handleAddRow = () => {
		const newRequirementNum = jobRequirements.length + 1;
		setJobRequirements([
			...jobRequirements,
			{
				requirementNum: newRequirementNum,
				percent: "",
				types: [{ id: Date.now(), type: "" }], // Unique ID
			},
		]);
	};

	// Handles adding a new horizontal type (OR operation)
	const handleAddType = (requirementNum) => {
		setJobRequirements((prevState) =>
			prevState.map((req) =>
				req.requirementNum === requirementNum
					? {
							...req,
							types: [...req.types, { id: Date.now(), type: "" }], // Unique ID
					  }
					: req
			)
		);
	};

	// Handles input changes for percent
	const handlePercentChange = (requirementNum, value) => {
		const numericValue = value.replace(/[^0-9]/g, "");
		setJobRequirements((prevState) =>
			prevState.map((req) => (req.requirementNum === requirementNum ? { ...req, percent: numericValue } : req))
		);
	};

	// Handles input changes for the dropdown type
	const handleTypeChange = (requirementNum, typeId, newValue, field) => {
		setJobRequirements((prevRequirements) =>
			prevRequirements.map((req) => {
				if (req.requirementNum === requirementNum) {
					return {
						...req,
						types: req.types.map((type) => {
							if (type.id === typeId) {
								return { ...type, [field]: newValue };
							}
							return type;
						}),
					};
				}
				return req;
			})
		);
	};

	const handleDeleteType = (requirementNum, typeId) => {
		setJobRequirements((prevRequirements) =>
			prevRequirements.map((req) =>
				req.requirementNum === requirementNum
					? { ...req, types: req.types.filter((type) => type.id !== typeId) }
					: req
			)
		);
	};

	const handleDeleteRequirement = (requirementNum) => {
		setJobRequirements((prevRequirements) =>
			prevRequirements.filter((req) => req.requirementNum !== requirementNum)
		);
	};

	const handleSave = () => {
		//Delete everything existing
		//Send the new array of objects as one API call?
		//UpdateTrigger
		return;
	};

	return (
		<Stack spacing={4} overflowX="auto" whiteSpace="nowrap" pb={4}>
			{jobRequirements.map((requirement, reqIndex) => (
				<Flex key={requirement.requirementNum} direction="column" align="flex-start">
					{reqIndex > 0 && (
						<Text my={2} ml={10}>
							AND
						</Text>
					)}
					<Flex direction="row" align="flex-end">
						{/* Delete button to remove the entire row (requirement) */}
						<Tooltip
							label="Delete Requirement"
							aria-label="Delete requirement tooltip"
							placement="top"
							hasArrow
						>
							<IconButton
								colorScheme="red"
								variant="ghost"
								size="lg"
								mr="2"
								icon={<i className="fas fa-trash-alt" />}
								onClick={() => handleDeleteRequirement(requirement?.requirementNum)}
							/>
						</Tooltip>

						<DataInput
							name={`${requirement?.requirementNum}`}
							type="number"
							label={`Requirement ${requirement?.requirementNum} Percentage`}
							onBlur={(e) => handlePercentChange(requirement?.requirementNum, e.target.value)}
							defaultValue={requirement?.percent ?? 0}
							pr="2"
							variant="outline"
							max={100}
							width="300px"
						/>

						{requirement?.types.map((type, typeIndex) => {
							// Get all selected types in the current requirement row
							const selectedTypes = requirement?.types.map((t) => t.type).filter((t) => t);

							// Filter out the selected types from DIVERSITY_CERTS for the current dropdown
							const availableOptions = DIVERSITY_CERTS.filter(
								(cert) => !selectedTypes.includes(cert.value) || cert.value === type?.type // Allow the current selection to remain available
							);

							return (
								<Flex key={type.id} align="center">
									<Stack>
										{requirement?.types?.length > 1 && (
											<Stack align="center">
												<Tooltip
													label="Delete Certification"
													aria-label="Delete certification tooltip"
													placement="top"
													hasArrow
												>
													<IconButton
														colorScheme="red"
														variant="ghost"
														size="s"
														icon={<i className="fas fa-minus-circle" />}
														onClick={() =>
															handleDeleteType(requirement?.requirementNum, type.id)
														}
													/>
												</Tooltip>
											</Stack>
										)}
										<DataInput
											name={`certification_${type?.id}`}
											type="select"
											label="Certification"
											placeholder="N/A"
											searchable
											options={availableOptions} // Use filtered options
											value={type?.type}
											optionValue="value"
											optionText="label"
											onOptionSelect={(e) =>
												handleTypeChange(requirement?.requirementNum, type?.id, e, "type")
											}
											variant="outline"
											pl="2"
											width="150px"
										/>
										{type.type === "SBE" && (
											<DataInput
												name={`certification_${type?.id}_certSubType`}
												type="select"
												label="Sub Type"
												placeholder="N/A"
												searchable
												options={DIVERSITY_SUB_TYPES}
												value={type?.certSubType ?? "N/A"}
												optionValue="value"
												optionText="label"
												onOptionSelect={(e) =>
													handleTypeChange(
														requirement?.requirementNum,
														type?.id,
														e,
														"certSubType"
													)
												}
												variant="outline"
												pl="2"
												width="150px"
											/>
										)}
									</Stack>
									{typeIndex < requirement?.types?.length - 1 && (
										<Text align="center" justify="center" ml={2}>
											OR
										</Text>
									)}
								</Flex>
							);
						})}
						<Button onClick={() => handleAddType(requirement?.requirementNum)} ml={2}>
							OR
						</Button>
					</Flex>
				</Flex>
			))}
			<Flex width="100%" justifyContent="space-between">
				<Button onClick={handleAddRow} ml={40}>
					{jobRequirements?.length === 0 ? "ADD" : "AND"}
				</Button>
				<Button colorScheme="green" onClick={handleSave}>
					SAVE
				</Button>
			</Flex>
		</Stack>
	);
}
