import React, { useEffect, useState } from "react";
import { Input, IconButton } from "@chakra-ui/react";

export default function TimecardHourInput2({ hour, item, disabled, style, onChange, hourIndex, openNotes }) {
	const [overtime, setOvertime] = useState(false);

	useEffect(() => {
		hour > 8 ? setOvertime(true) : setOvertime(false);
	}, [hour]);

	return (
		<>
			<Input
				bg="red"
				borderBottomWidth={2}
				variant="flushed"
				placeholder="0.0"
				fontWeight="semibold"
				position="relative"
				// color={overtime ? "red" : "gray.600"}
				value={hour === "0.0" ? "" : hour}
				size="md"
				textAlign="center"
				type="number"
				isDisabled={!Boolean(item.keyId) || disabled || item.isLocked()}
				maxW="50px"
				onChange={(ev) => {
					ev.stopPropagation();
					onChange(ev, hourIndex, false);
				}}
				onBlur={(ev) => {
					ev.stopPropagation();
					onChange(ev, hourIndex, true);
				}}
			/>
			<IconButton
				icon={
					<i
						className={
							"fa-lg " + (item.hasNotes(hourIndex) ? "fas fa-clipboard-check" : "far fa-clipboard")
						}
					></i>
				}
				color="gray.500"
				position="absolute"
				right={-4}
				bg="none"
				hidden={!Boolean(item.hasNotes(hourIndex))}
				size="sm"
				variant="ghost"
				// cursor={!Boolean(item.keyId) ? "not-allowed" : "pointer"}
				onClick={(ev) => {
					ev.stopPropagation();
					openNotes(hourIndex);
				}}
				isDisabled={!Boolean(item.keyId)}
				tabIndex="-1"
			/>
		</>
	);
}
