import React from "react";

//EMPLOYEE PORTAL
import Home from "../../Home/Home";
import Employee from "../../Employee/Employee";
import Team from "../../Employee/Team";
import TimecardRoutes from "../../../legacy/Payroll/routes/TimecardRoutes";
import TimecardNew from "../../Payroll/components/TimecardNew";
import Projects from "../../Employee/Projects";

import Contacts from "../../Employee/Contacts";
import Newsletter from "../../Employee/Newsletter";

//PAYROLL
import PayrollRoutes from "../../../legacy/Payroll/routes/PayrollRoutes";

//SCHEDULES
import Schedules from "../../Schedules/Schedules";

//PURCHASING
import Purchasing from "../../Purchasing/Purchasing";

// OPERATIONS
import MFR from "../../Contracts/MFR";

// HUMAN RESOURCES
import HRAdmin from "../../HRAdmin/HRAdmin";

// DEV OPS
import DevOps from "../../DevOps/DevOps";
import Examples from "../../../core/Examples/Examples";
import EmbedPowerApp from "../../PowerPlatform/EmbedPowerApp";
import EmbedExcelFile from "../../PowerPlatform/EmbedExcelFile";
import PowerBIViewer from "../../PowerPlatform/PowerBIViewer";
import PowerBIDashboard from "../../PowerPlatform/PowerBIDashboard";
import ReportViewer from "../../../core/Reports/Reports";
import MFRDiversityPage from "../../MFR/pages/Diversity/MFRDiversityPage";

export const routes = [
	[
		{
			name: "Home",
			url: "/home",
			icon: "fas fa-home fa-2x fa-fw",
			page: <Home pageName="Employee Portal" />,
		},
		{
			name: "Employee",
			url: "/employee",
			icon: "fas fa-user fa-2x fa-fw",
			page: <Employee tabVal={0} />,
			subMenu: [
				{
					name: "Time Off Requests",
					url: "/employee/timeoff",
					icon: "fas fa-plane-departure fa-fw fa-lg",
					page: <Employee tabVal={0} />,
				},
				{
					name: "Contact Info",
					url: "/employee/contact",
					icon: "fas fa-address-card fa-fw fa-lg",
					page: <Employee tabVal={1} />,
				},
				{
					name: "My Reviews",
					url: "/employee/reviews",
					icon: "fas fa-file-signature fa-fw fa-lg",
					page: <Employee tabVal={2} />,
				},
			],
		},

		{
			name: "Team",
			url: "/team",
			icon: "fas fa-user-friends fa-2x fa-fw",
			access: ["isTeamManagerYN", "isTeamReviewerYN"],
			page: <Team tabVal={0} />,
			subMenu: [
				{
					name: "Team Members",
					url: "/team/members",
					icon: "fas fa-sitemap fa-fw fa-lg",
					access: ["isTeamManagerYN", "isTeamReviewerYN"],
					page: <Team tabVal={0} />,
				},
				{
					name: "Team Reviews",
					url: "/team/reviews",
					icon: "far fa-calendar-check fa-fw fa-lg",
					access: ["isTeamReviewerYN"],
					page: <Team tabVal={1} />,
				},
			],
		},

		{
			name: "Timecard",
			url: "/timecard",
			icon: "fas fa-clock fa-2x fa-fw",
			subMenu: [
				{
					name: "My Timecard",
					url: "/timecard",
					icon: "fas fa-clock fa-fw fa-lg",
					page: <TimecardRoutes pageName="My Timecard" tabVal={0} />,
				},
				{
					name: "Crew Entry",
					url: "/timecard/crewtime",
					icon: "fas fa-hard-hat fa-fw fa-lg",
					page: <TimecardRoutes pageName="Crew Time" tabVal={1} />,
					access: ["isPayrollUserYN", "showTimecardCrewEntryYN"],
				},
				{
					name: "Approve Time",
					url: "/timecard/approvetime",
					icon: "fas fa-tasks fa-fw fa-lg",
					page: <TimecardRoutes pageName="Approve Time" tabVal={2} />,
					access: ["isPayrollUserYN", "showTimecardApprovalYN"],
				},
				{
					name: "Past Paystubs",
					url: "/timecard/paystubs",
					icon: "fas fa-money-check-alt fa-fw fa-lg",
					page: <TimecardRoutes pageName="Past Paystubs" />,
				},
			],
		},

		{
			name: "Contacts",
			url: "/contacts",
			icon: "fas fa-address-book fa-2x fa-fw",
			subMenu: [
				{
					name: "Employees",
					url: "/contacts/employees",
					icon: "fas fa-users fa-fw fa-lg",
					page: <Contacts pageName="Employees" isContacts />,
				},
			],
		},

		{
			name: "Projects",
			url: "/projects",
			icon: "fas fa-city fa-2x fa-fw",
			page: <Projects tabVal={0} />,
			subMenu: [
				{
					name: "Active Projects",
					url: "/projects/active",
					icon: "far fa-thumbs-up fa-fw fa-lg",
					page: <Projects tabVal={0} />,
				},
				{
					name: "Complete Projects",
					url: "/projects/completed",
					icon: "far fa-handshake fa-fw fa-lg",
					page: <Projects tabVal={1} />,
				},
			],
		},

		{
			name: "Newsletter",
			url: "/newsletter",
			icon: "fas fa-bullhorn fa-2x fa-fw",
			page: <Newsletter />,
		},
	],

	[
		{
			name: "HR Admin",
			url: "/hr",
			icon: "fas fa-hand-holding-heart fa-2x fa-fw",
			access: [
				"isPortalAdminYN",
				"isDevOpsUserYN",
				"isExecUserYN",
				"isTeamManagerYN",
			],
			page: <HRAdmin tabVal={0} />,
			subMenu: [
				{
					name: "HR Employees",
					url: "/hr/employees",
					icon: "fas fa-users fa-fw fa-lg",
					access: [
						"isPortalAdminYN",
						"isDevOpsUserYN",
						"isExecUserYN",
						"isLongevityUserYN",
					],
					page: <HRAdmin tabVal={0} />,
				},
				{
					name: "HR Calendar",
					url: "/hr/calendar",
					icon: "fas fa-plane-departure fa-fw fa-lg",
					access: [
						"isPortalAdminYN",
						"isDevOpsUserYN",
						"isExecUserYN",
						"isTeamManagerYN",
					],
					page: <HRAdmin tabVal={1} />,
				},
				{
					name: "HR Reviews",
					url: "/hr/reviews",
					icon: "far fa-calendar-check fa-fw fa-lg",
					access: ["isPortalAdminYN", "isDevOpsUserYN"],
					page: <HRAdmin tabVal={2} />,
				},
				{
					name: "HR Forms",
					url: "/hr/forms",
					icon: "fas fa-file-signature fa-fw fa-lg",
					access: ["isPortalAdminYN", "isDevOpsUserYN"],
					page: <HRAdmin tabVal={3} />,
				},
				{
					name: "HR Templates",
					url: "/hr/templates",
					icon: "fas fa-copy fa-fw fa-lg",
					access: ["isPortalAdminYN", "isDevOpsUserYN"],
					page: <HRAdmin tabVal={4} />,
				},
				{
					name: "HR Longevity",
					url: "/hr/longevity",
					icon: "fas fa-trophy fa-fw fa-lg",
					access: [
						"isPortalAdminYN",
						"isDevOpsUserYN",
						"isLongevityUserYN",
					],
					page: <HRAdmin tabVal={5} />,
				},
			],
		},
		{
			name: "Payroll",
			url: "/payroll",
			icon: "fas fa-money-bill-wave fa-2x fa-fw",
			access: [
				"isPayrollUserYN",
				"isDevOpsUserYN",
				"showEmployeeHistoryYN",
			],
			subMenu: [
				{
					name: "Weekly Payroll",
					url: "/payroll/upload",
					icon: "fas fa-cloud-upload-alt fa-fw fa-lg",
					page: (
						<PayrollRoutes pageName="Payroll Upload" tabVal={0} />
					),
					access: ["isPayrollUserYN", "isDevOpsUserYN"],
				},
				{
					name: "PR Employees",
					url: "/payroll/employees",
					icon: "fas fa-users fa-fw fa-lg",
					page: <PayrollRoutes pageName="Payroll Employees" />,
					access: ["isPayrollUserYN", "isDevOpsUserYN"],
				},
				{
					name: "Merit Increases",
					url: "/payroll/merit",
					icon: "fas fa-hand-holding-usd fa-fw fa-lg",
					access: ["isDevOpsUserYN", "showEmployeeHistoryYN"],
					page: <PayrollRoutes pageName="Merit Increases" />,
				},
			],
		},
		{
			name: "Purchasing",
			url: "/purchasing",
			icon: "fas fa-dollar-sign fa-2x fa-fw",
			page: <Purchasing tabVal={0} />,
			subMenu: [
				{
					name: "Diversity Vendors",
					url: "/purchasing/vendors",
					icon: "fas fa-award fa-fw fa-lg",
					page: <Purchasing tabVal={0} />,
				},
				{
					name: "Purchase Order",
					url: "/purchasing/po",
					icon: "fas fa-file-invoice-dollar fa-fw fa-lg",
					access: ["isDevOpsUserYN"],
					page: <Purchasing tabVal={1} />,
				},
			],
		},

		{
			name: "Operations",
			url: "/operations",
			icon: "fas fa-hard-hat fa-2x fa-fw",
			access: [
				"isDevOpsUserYN",
				"isExecUserYN",
				"isOperationsExecUserYN",
				"isOperationsUserYN",
				"showPEReviewYN",
				"isPurchasingUserYN",
				"isPurchasingManagerYN",
			],
			subMenu: [
				{
					name: "Monthly Forecast",
					url: "/operations/mfr",
					icon: "fas fa-calendar-alt fa-lg fa-fw",
					access: [
						"isDevOpsUserYN",
						"isExecUserYN",
						"isOperationsExecUserYN",
						"isOperationsUserYN",
					],
					page: (
						<MFR
							pageName="Monthly Forecast Report"
							tabVal={0}
							template="MonthlyForecast"
						/>
					),
				},
				{
					name: "Subcontractors",
					url: "/operations/msa",
					icon: "fas fa-list fa-fw fa-lg",
					access: [
						"isDevOpsUserYN",
						"isOperationsExecUserYN",
						"isOperationsUserYN",
						"isPurchasingUserYN",
						"isPurchasingManagerYN",
					],
					page: (
						<EmbedExcelFile pageName="Master Subcontract Agreement" />
					),
				},
				{
					name: "PE Review Form",
					url: "/operations/reviewform",
					icon: "fas fa-file-alt fa-lg fa-fw",
					access: ["isDevOpsUserYN", "showPEReviewYN"],
					page: (
						<EmbedPowerApp
							title="PE Review Form"
							baseURL="https://web.powerapps.com/webplayer/iframeapp?source=iframe&appId=/providers/Microsoft.PowerApps/apps/"
							template="MonthlyForecast"
							appID="f464da40-32c3-4d29-9d63-45369d49612a"
						/>
					),
				},
			],
		},

		{
			name: "Schedules",
			url: "/schedules",
			icon: "fas fa-tasks fa-2x fa-fw ",
			access: [
				"isDevOpsUserYN",
				"isExecUserYN",
				"isOperationsExecUserYN",
				"isDetailingUserYN",
				"isStartUpUserYN",
			],
			subMenu: [
				{
					name: "Beta D&F Schedule 2.0",
					url: "/schedules/d&f",
					icon: "far fa-calendar-check fa-lg fa-fw",
					access: [
						"isDevOpsUserYN",
						"isExecUserYN",
						"isOperationsExecUserYN",
						"isDetailingUserYN",
						"isStartUpUserYN",
					],
					page: <Schedules />,
				},
				{
					name: "Beta Start Up Planner",
					url: "/schedules/sup",
					icon: "fas fa-flag-checkered fa-lg fa-fw",
					access: [
						"isDevOpsUserYN",
						"isExecUserYN",
						"isOperationsExecUserYN",
						"isDetailingUserYN",
						"isStartUpUserYN",
					],
					page: <Schedules scopeID={"13"} />,
				},
			],
		},
	],

	[
		{
			name: "Dev Ops",
			url: "/devops",
			icon: "fas fa-laptop-code fa-2x fa-fw",
			access: [
				"isDevOpsUserYN",
				"isExecUserYN",
				"showDevOpsPresentationYN",
			],
			subMenu: [
				{
					name: "The Dev Team",
					url: "/devops/team",
					icon: "fas fa-code fa-fw fa-lg ",
					access: [
						"isDevOpsUserYN",
						"isExecUserYN",
						"showDevOpsPresentationYN",
					],
					page: <DevOps pageName=" Dev Ops" />,
				},
				{
					name: "Themes & Colors",
					url: "/examples/theme",
					icon: "fas fa-palette fa-fw fa-lg ",
					access: ["isDevOpsUserYN"],
					page: <Examples pageName="Theme & Colors" type="theme" />,
				},
				{
					name: "UI/UX Components",
					url: "/examples/ui",
					icon: "fab fa-react fa-fw fa-lg ",
					access: ["isDevOpsUserYN"],
					page: <Examples pageName="UI Components" type="ui" />,
				},
				{
					name: "Framer Motion",
					url: "/examples/motion",
					icon: "fas fa-wind fa-fw fa-lg",
					access: ["isDevOpsUserYN"],
					page: (
						<Examples
							pageName="Framer Motion Components"
							type="motion"
						/>
					),
				},
				{
					name: "Diversity",
					url: "/examples/diversity",
					icon: "fas fa-wind fa-fw fa-lg",
					access: ["isDevOpsUserYN"],
					page: <MFRDiversityPage pageName="Diversity" type="motion" />,
				},
			],
		},

		{
			name: "Power BI",
			url: "/powerbi",
			icon: "fas fa-chart-bar fa-2x fa-fw",
			access: ["isDevOpsUserYN"],
			subMenu: [
				{
					name: "Dev Ops Reports",
					url: "/powerbi/devops",
					icon: "fas fa-chart-pie fa-fw fa-lg",
					access: ["isDevOpsUserYN"],
					page: <PowerBIViewer pageName="Dev Ops" module="DEV" />,
				},
				{
					name: "Exec Dashboard",
					url: "/powerbi/exec",
					icon: "fas fa-user-tie fa-fw fa-lg",
					access: ["isDevOpsUserYN"],
					page: (
						<PowerBIViewer
							pageName="Executive Dashboards"
							module="EXEC"
						/>
					),
				},
				{
					name: "Operations Reports",
					url: "/powerbi/ops",
					icon: "fas fa-chart-line fa-fw fa-lg",
					access: ["isDevOpsUserYN"],
					page: (
						<PowerBIViewer
							pageName="Operations Dashboards"
							module="OPS"
						/>
					),
				},

				{
					name: "Power Apps",
					url: "/powerbi/apps",
					icon: "far fa-window-maximize fa-lg fa-fw",
					access: ["isDevOpsUserYN"],
					page: (
						<EmbedPowerApp
							title="PE Review Form"
							baseURL="https://web.powerapps.com/webplayer/iframeapp?source=iframe&appId=/providers/Microsoft.PowerApps/apps/"
							template="MonthlyForecast"
							appID="f464da40-32c3-4d29-9d63-45369d49612a"
						/>
					),
				},
				{
					name: "Embed & Preview",
					url: "/powerbi/embed",
					icon: "fas fa-link fa-fw fa-lg",
					access: ["isDevOpsUserYN"],
					page: <PowerBIDashboard pageName="Embed Reports" />,
				},
			],
		},
		{
			name: "Reports",
			url: "/reports",
			icon: "fas fa-paste fa-fw fa-2x",
			access: ["isDevOpsUserYN"],
			page: <ReportViewer pageName="Report Viewer" tabVal={0} />,
		},

		{
			name: "Timecard New",
			url: "/new/timecard",
			icon: "fas fa-clock fa-2x fa-fw",
			access: ["isDevOpsUserYN"],
			page: <TimecardNew />,
			subMenu: [
				{
					name: "My Time",
					url: "/new/timecard/mytime",
					icon: "fas fa-clock fa-fw fa-lg",
					page: <TimecardNew tabVal={0} />,
					access: ["isDevOpsUserYN"],
				},
				{
					name: "Crew Time",
					url: "/new/timecard/crewtime",
					icon: "fas fa-hard-hat fa-fw fa-lg",
					page: <TimecardNew tabVal={1} />,
					access: ["isDevOpsUserYN"],
				},
				{
					name: "Approve Time",
					url: "/new/timecard/approval",
					icon: "fas fa-tasks fa-fw fa-lg",
					page: <TimecardNew tabVal={2} />,
					access: ["isDevOpsUserYN"],
				},
				{
					name: "Resource Groups",
					url: "/new/timecard/groups",
					icon: "fas fa-sitemap fa-fw fa-lg",
					page: <TimecardNew tabVal={3} />,
					access: ["isDevOpsUserYN"],
				},
				{
					name: "Past Paystubs",
					url: "/new/timecard/paystubs",
					icon: "fas fa-money-check-alt fa-fw fa-lg",
					page: <TimecardNew tabVal={4} />,
					access: ["isDevOpsUserYN"],
				},
			],
		},
	],

	// [
	// 	{
	// 		name: "Settings",
	// 		url: "/user",
	// 		icon: "fas fa-cog fa-fw fa-2x",
	// 		page: <Profile pageName="User Settings" tabVal={3} />,
	// 	},
	// ],
];
