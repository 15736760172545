import React, { useState, useEffect } from "react";
import { Stack } from "@chakra-ui/react";
import { formatValue } from "../../../../../helperFunctions";
import ProgressBar from "./ProgressBar";
import PieChart from "./PieChart";
import NUMERATOR from "../Constants/Numerator";
import DENOMINATOR from "../Constants/Denominator";

const testD3Data = [
	{ label: "SBE", value: 150000 },
	{ label: "WBE", value: 100000 },
	{ label: "Other", value: 750000 },
];

const progressBarData = [
	{
		label: "SBE",
		spentToDate: 150000,
		totalRequired: 200000,
		actualAndCommitedCost: 1,
		actualCost: 2,
		currContractAmt: 3,
		projContractAmt: 4,
		bidContractAmt: 5,
	},
	{
		label: "WBE",
		spentToDate: 100000,
		totalRequired: 100000,
		actualAndCommitedCost: 1,
		actualCost: 2,
		currContractAmt: 3,
		projContractAmt: 4,
		bidContractAmt: 5,
	},
];

export default function SpentToDatePage() {
	const [numerator, setNumerator] = useState(NUMERATOR[0].title);
	const [denominator, setDenominator] = useState(DENOMINATOR[0].title);
	const [progressData, setProgressData] = useState(
		progressBarData.map((data) => ({
			...data,
			percentTowardsGoal: formatValue((data.spentToDate / data.totalRequired) * 100, 2, "percent"),
			formattedSpentToDate: formatValue(data.spentToDate, 2, "currency"),
			formattedTotalRequired: formatValue(data.totalRequired, 2, "currency"),
			formattedTotalRemaining: formatValue(data.totalRequired - data.spentToDate, 2, "currency"),
		}))
	);

	useEffect(() => {
		const updateProgressData = () => {
			setProgressData(
				progressBarData.map((data) => {
					let spentToDate = data.spentToDate;
					let totalRequired = data.totalRequired;

					// Update based on numerator selection
					if (numerator === "actualAndCommitedCost") {
						spentToDate = data.actualAndCommitedCost;
					} else if (numerator === "actualCost") {
						spentToDate = data.actualCost;
					}

					// Update based on denominator selection
					if (denominator === "currContractAmt") {
						totalRequired = data.currContractAmt;
					} else if (denominator === "projContractAmt") {
						totalRequired = data.projContractAmt;
					} else if (denominator === "bidContractAmt") {
						totalRequired = data.bidContractAmt;
					}

					return {
						...data,
						percentTowardsGoal: formatValue((spentToDate / totalRequired) * 100, 2, "percent"),
						formattedSpentToDate: formatValue(spentToDate, 2, "currency"),
						formattedTotalRequired: formatValue(totalRequired, 2, "currency"),
						formattedTotalRemaining: formatValue(totalRequired - spentToDate, 2, "currency"),
					};
				})
			);
		};

		updateProgressData();
	}, [numerator, denominator]);

	return (
		<Stack w="full" px={5} spacing={6}>
			<PieChart
				data={testD3Data}
				numerator={numerator}
				setNumerator={setNumerator}
				denominator={denominator}
				setDenominator={setDenominator}
			/>
			{progressData.map((data, index) => (
				<ProgressBar key={data.label} data={data} />
			))}
		</Stack>
	);
}
