const DENOMINATOR = [
	{
		title: "Current Contract Amount",
		value: "currContractAmt",
	},
	{ title: "Projected Contract Amount", value: "projContractAmt" },
	{
		title: "Bid Contract Amount",
		value: "bidContractAmt",
	},
];

export default DENOMINATOR;
