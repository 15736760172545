import React from "react";
import {
	Flex,
	Spacer,
	Text,
	Heading,
	Input,
	VStack,
	Container,
	HStack,
	Button,
	Icon,
	InputGroup,
	Center,
	InputLeftAddon,
} from "@chakra-ui/react";

import { Email, Span, renderEmail } from "react-html-email";
import { IoShieldHalfOutline } from "react-icons/io5";
import { v4 } from "uuid";

import MainContent from "../../core/ReactTable/layout/MainContent";
import PPMLogo from "../../core/Logos/components/PPMLogo";
import { portalUser } from "../../App";
import MeritIncreasesUnlocked from "./MeritIncreasesUnlocked";
// import { log } from "../../helperFunctions";

export default class MeritIncreasesLocked extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			authCode: null,
			userCode: null,
			isInvalid: false,
			isAuthorized: true,
			isLoading: false,
		};

		this.init = this.init.bind(this);
		this.getAuthCode = this.getAuthCode.bind(this);
		this.sendAuthCode = this.sendAuthCode.bind(this);
		this.changeAuthCode = this.changeAuthCode.bind(this);
		this.setIsLoading = this.setIsLoading.bind(this);
	}

	componentDidMount() {
		this.init(this.state.isAuthorized);
	}

	init(isAuthorized = false) {
		document.body.scrollTop = document.documentElement.scrollTop = 0;
		this.setState(
			{
				isAuthorized,
				isLoading: isAuthorized,
			},
			async () => {
				if (!isAuthorized) {
					await this.changeAuthCode();
				}
			}
		);
	}

	getAuthCode(authCode = null) {
		if (authCode?.length >= 6) {
			authCode = authCode
				?.toUpperCase()
				?.replace(/\s+/g, "")
				?.substring(0, 6);
		}

		return authCode;
	}

	async sendAuthCode(authType = "email") {
		let authCode = this.getAuthCode(v4());

		if (portalUser.user?.usersAPI && authType === "email") {
			let from = "PPM Portal<no-reply@ppmportal.com>";
			let subject = "PPM Portal User Verification Code";
			let body = renderEmail(
				<Email title={subject} style={{ width: "100%" }}>
					<Span>
						Please enter the following the 6-digit verification code
						into the PPM Portal to view employee salary and bonus
						information:
						<h3 style={{ color: "teal" }}>{authCode}</h3>
					</Span>
				</Email>
			);

			let msg = {
				from: from,
				to: portalUser.user?.email,
				subject: subject,
				html: body,
			};

			await portalUser.user?.usersAPI.SendgridEmail(msg);
		}
		this.setState(
			{
				authCode: authCode,
				userCode: null,
				isInvalid: false,
				isAuthorized: false,
			},
			() => this.changeAuthCode()
		);
	}

	async changeAuthCode(ev) {
		let authCode = this.state.authCode;
		let userCode = this.getAuthCode(ev?.target?.value);
		let isInvalid = false;
		let isAuthorized = false;

		// if (!Boolean(authCode?.length === 6)) {
		// 	await this.sendAuthCode("email");
		// } else {
		if (userCode?.length === 6 && authCode !== userCode) {
			isInvalid = true;
		} else if (userCode?.length === 6 && authCode === userCode) {
			isAuthorized = true;
		}

		this.setState(
			{
				authCode,
				userCode,
				isInvalid,
				isAuthorized,
			},
			() => this.setIsLoading(isAuthorized)
		);
		// }
	}

	setIsLoading(isAuthorized = false) {
		let isLoading = this.state.isLoading;

		if ((isAuthorized && !isLoading) || (!isAuthorized && isLoading)) {
			isLoading = isAuthorized;
			setTimeout(() => {
				this.setState({ isLoading });
			}, 2000);
		}
	}

	render() {
		// let isInvalid = this.state.isInvalid;
		let isAuthorized = this.state.isAuthorized;
		let isLoading = this.state.isLoading;

		let authHeading = "To access this page, please verify your identity";
		let authSubheading = "Please enter the code sent to your email below";

		let authFooter =
			"If you leave this page, you will need to re-verify your identity";

		return (
			<>
				{!isAuthorized || !isLoading ? (
					<MainContent
						title="Verification Required"
						tabVal={0}
						tabs={["Locked"]}
						{...this.props}
					>
						<Flex
							zIndex={10}
							direction="column"
							align="center"
							justify="center"
							pos="absolute"
							bg="teal.500"
							opacity={0.9}
							top="0"
							w="full"
							minH="100vh"
							maxH="100vh"
							overflow="hidden"
						>
							<Flex flex={1} />
							<Icon
								flex={8}
								ml={{ base: "0px", md: "-112px" }}
								p="6"
								as={IoShieldHalfOutline}
								color="whiteAlpha.800"
								boxSize="full"
								maxH="container.lg"
								maxW="container.lg"
							/>
							<Flex flex={4} />
						</Flex>

						<Flex
							zIndex={11}
							pos="absolute"
							top="0"
							direction="column"
							w="full"
							minH="100vh"
							maxH="100vh"
							h="full"
							bg="blackAlpha.500"
							align="center"
							justify="center"
							overflow="hidden"
						>
							<Container mt="25vh" w="full" h="full" px="0">
								<Center
									h="full"
									p="6"
									shadow="xl"
									rounded="10"
									maxH="sm"
									bg="whiteAlpha.900"
									maxW="xl"
									ml={{ base: "0px", md: "-84px" }}
								>
									<VStack spacing={6}>
										<PPMLogo color="teal.700" h="65px" />

										<VStack spacing={2} textAlign="center">
											<Heading
												color="gray.500"
												size="sm"
												letterSpacing={1}
												textTransform="uppercase"
											>
												{authHeading}
											</Heading>
											<Text
												as="i"
												color="gray.500"
												letterSpacing={1}
												fontSize="sm"
											>
												{authFooter}
											</Text>

											{this.state.authCode &&
												!this.state.authFailed &&
												!this.state.isAuthorized && (
													<Heading
														color="gray.500"
														size="xs"
														letterSpacing={1}
														textTransform="uppercase"
													>
														{authSubheading}
													</Heading>
												)}

											{this.state.authCode &&
												this.state.authFailed &&
												!this.state.isAuthorized && (
													<Heading
														color="red.500"
														size="xs"
														letterSpacing={1}
														textTransform="uppercase"
													>
														{
															"Invalid Code, Please check your email and try again"
														}
													</Heading>
												)}
											{this.state.isAuthorized && (
												<Heading
													color="green.600"
													size="sm"
													letterSpacing={1}
													textTransform="uppercase"
												>
													Identify verification is
													complete, access granted
												</Heading>
											)}
										</VStack>

										<Center>
											<InputGroup>
												<InputLeftAddon
													px="3"
													borderColor={
														this.state.authCode &&
														this.state.authFailed
															? "red.500"
															: "gray.300"
													}
													border="2px"
													pointerEvents="none"
													children={
														<Heading
															key={
																"authorized-" +
																this.state
																	.isAuthorized
															}
															color={
																this.state
																	.authCode &&
																this.state
																	.authFailed
																	? "red.500"
																	: this.state
																			.isAuthorized
																	? "green.600"
																	: "gray.500"
															}
															size="sm"
														>
															<i
																className={
																	"fas " +
																	(this.state
																		.isAuthorized
																		? "fa-lock-open"
																		: this
																				.state
																				.authCode &&
																		  this
																				.state
																				.authFailed
																		? "fa-exclamation-triangle"
																		: "fa-lock") +
																	" fa-fw fa-lg"
																}
															></i>
														</Heading>
													}
												/>
												<Input
													bg="white"
													color="teal.600"
													fontWeight="bold"
													letterSpacing={2}
													textTransform="uppercase"
													textAlign="center"
													focusBorderColor={
														this.state.authFailed
															? "red.500"
															: "teal.600"
													}
													borderColor="gray.300"
													border="2px"
													fontSize="xl"
													placeholder="Verification Code"
													_placeholder={{
														color: "gray.300",
														fontWeight: "normal",
													}}
													maxW="xs"
													value={
														this.state.enteredCode
													}
													isInvalid={
														this.state.authFailed
													}
													_invalid={{
														color: "red",
														borderColor: "red.500",
													}}
													errorBorderColor="red.500"
													isDisabled={
														!this.state.authCode
													}
													_disabled={{
														borderColor: "gray.300",
														cursor: "not-allowed",
													}}
													onChange={
														this.changeAuthCode
													}
													maxLength="6"
												/>
											</InputGroup>
										</Center>

										<Button
											textTransform="uppercase"
											onClick={async () =>
												await this.sendAuthCode("email")
											}
											p={2}
											colorScheme="teal"
										>
											<HStack align="center">
												<i className="fas fa-envelope fa-fw fa-lg"></i>
												<Text letterSpacing={1}>
													{(this.state.authCode
														? "Re-Send"
														: "Send") + " Code"}
												</Text>
											</HStack>
										</Button>
									</VStack>
								</Center>
							</Container>
							<Spacer />
						</Flex>
					</MainContent>
				) : (
					<MeritIncreasesUnlocked />
				)}
			</>
		);
	}
}
