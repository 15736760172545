import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Stack, Flex, Divider, Text } from "@chakra-ui/react";
import DataInput from "../../../../../core/Inputs/data/DataInput";
import NUMERATOR from "../Constants/Numerator";
import DENOMINATOR from "../Constants/Denominator";

const spacing = { vertSpacing: 2, horzSpacing: 2, leftFlex: 4, rightFlex: 2 };

const PieChart = ({
	data,
	numerator,
	setNumerator,
	denominator,
	setDenominator,
	percentTowardsGoal,
	spentToDate,
	totalRequired,
	totalRemaining,
}) => {
	const svgRef = useRef();
	const newTotal = data.reduce((total, item) => total + item.value, 0);

	useEffect(() => {
		const width = 300;
		const height = 300;
		const radius = Math.min(width, height) / 2;

		const color = d3.scaleOrdinal(d3.schemeCategory10);

		const pie = d3.pie().value((d) => d.value);

		const arc = d3.arc().innerRadius(0).outerRadius(radius);

		// Clear any existing SVG content
		d3.select(svgRef.current).selectAll("*").remove();

		const tooltip = d3
			.select("body")
			.append("div")
			.attr("class", "tooltip")
			.style("position", "absolute")
			.style("padding", "5px")
			.style("background-color", "rgba(0, 0, 0, 0.7)")
			.style("color", "#fff")
			.style("border-radius", "5px")
			.style("opacity", 0)
			.style("pointer-events", "none");

		const svg = d3
			.select(svgRef.current)
			.attr("width", width)
			.attr("height", height)
			.append("g")
			.attr("transform", `translate(${width / 2}, ${height / 2})`);

		const arcs = svg.selectAll(".arc").data(pie(data)).enter().append("g").attr("class", "arc");

		const paths = arcs
			.append("path")
			.attr("d", arc)
			.style("fill", (d, i) => color(i))
			.on("mouseover", function (event, d) {
				const percentage = ((d.value / newTotal) * 100).toFixed(2);
				// setHoveredIndex(d.index);

				// Only dim other sections
				paths
					.filter((pd) => pd !== d)
					.transition()
					.duration(200)
					.style("opacity", 0.8);

				tooltip.transition().duration(200).style("opacity", 1);

				tooltip
					.html(`${d.data.label}: ${percentage}%<br/>Amount: $${d.data.value.toLocaleString()}`)
					.style("left", `${event.pageX + 10}px`)
					.style("top", `${event.pageY - 10}px`);
			})
			.on("mouseout", function (event, d) {
				// setHoveredIndex(null);

				// Reset opacity for all sections
				paths.transition().duration(200).style("opacity", 1);

				tooltip.transition().duration(200).style("opacity", 0);
			});

		// Add labels
		arcs.append("text")
			.attr("transform", (d) => `translate(${arc.centroid(d)})`)
			.attr("dy", ".35em")
			.style("text-anchor", "middle")
			.style("fill", "white")
			.style("font-size", "15px")
			.text((d) => d.data.label);

		return () => {
			tooltip.remove();
		};
	}, [data, newTotal]);

	return (
		<Flex>
			<Flex flex="1" align="center" justify="center">
				<Stack align="center">
					<Text fontWeight="bold">Spent To Date</Text>
					<svg ref={svgRef}></svg>
				</Stack>
			</Flex>
			<Stack flex="1" justify="center" p={4}>
				<Flex justify="center">
					<Text fontWeight="bold">Calculation Options</Text>
				</Flex>
				<Stack>
					<DataInput
						key="numerator"
						name="numerator"
						type="select"
						searchable
						options={NUMERATOR}
						value={numerator}
						optionValue="value"
						optionText="title"
						flex={spacing.leftFlex}
						variant="outline"
						isModal
						onOptionSelect={(ev) => {
							setNumerator(ev);
						}}
					/>
					<Divider borderColor="teal.500" borderWidth="2px" />
					<DataInput
						key="denominator"
						name="denominator"
						type="select"
						searchable
						options={DENOMINATOR}
						value={denominator}
						optionValue="value"
						optionText="title"
						flex={spacing.leftFlex}
						variant="outline"
						isModal
						onOptionSelect={(ev) => {
							setDenominator(ev);
						}}
					/>
				</Stack>
			</Stack>
		</Flex>
	);
};

export default PieChart;
