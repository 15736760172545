import React, { useRef } from "react";
import { v4 } from "uuid";
import { Center, Button, useToast } from "@chakra-ui/react";
import { log } from "../../helperFunctions";

export default function ToastPopup({
	handleUploadRetry = () => log("handleUploadRetry"),
	status,
	updates = {
		uploadId: v4(),
	},
	updateTitle,
	toastShown = () => log("Toast Shown"),
	duration = 3000,
}) {
	const toast = useToast();
	const toastIdRef = useRef();

	const handleRetry = (updates) => {
		handleUploadRetry(updates);
		if (toastIdRef.current) {
			toast.close(toastIdRef.current);
		}
	};

	if (Boolean(status) && !toast.isActive(updates.uploadId)) {
		let title = (
			<Center>
				<span>{updateTitle}</span>
				{status === "error" && (
					<Button
						size="sm"
						colorScheme="whiteAlpha"
						onClick={() => {
							handleRetry(updates);
						}}
					>
						RETRY
					</Button>
				)}
			</Center>
		);
		toastIdRef.current = toast({
			id: updates.uploadId,
			title: title,
			status: status.toLowerCase(),
			duration,
			isClosable: true,
		});
		toastShown();
	}

	return <></>;
}
