import React, { useState, useMemo } from "react";
import {
	Flex,
	Input,
	useNumberInput,
	// NumberInput,
	// NumberInputField,
	// NumberInputStepper,
	Tooltip,
	useBoolean,
	IconButton,
} from "@chakra-ui/react";
import { formatValue, getDeviceType } from "../../../helperFunctions";

export default function TimecardEntryHourInput({
	hour,
	item,
	disabled,
	onChange,
	hourIndex,
	openNotes,
}) {
	const deviceType = getDeviceType();

	const [isHovering, setHovering] = useBoolean(deviceType === "mobile");

	const isOverTime = useMemo(() => Boolean(hour > 8), [hour]);

	const inputDisabled = useMemo(
		() => !Boolean(item.keyId) || disabled || item.isLocked(),
		[item, disabled]
	);
	const inputAvailable = useMemo(
		() => Boolean(item.keyId) && (!disabled || !item.isLocked()),
		[item, disabled]
	);

	const hasNotes = useMemo(() => item.hasNotes(hourIndex), [item, hourIndex]);
	const notesIcon = useMemo(
		() =>
			(hasNotes ? "fas fa-clipboard-check" : "far fa-clipboard") +
			" fa-lg",
		[hasNotes]
	);

	const setMouseHovering = () => {
		if (inputAvailable && !isHovering) {
			setHovering.on();
		} else if (isHovering) {
			setHovering.off();
		}
	};

	const [localVal, setLocalVal] = useState("");

	useMemo(() => {
		setLocalVal(hour);
	}, [hour]);

	const uploadHours = (ev) => {
		ev.stopPropagation();
		let value = (Math.round(ev.target.value * 2) / 2).toFixed(1);
		ev.target.value = formatValue(value, 1, "number");
		onChange(ev, hourIndex, true);
	};

	let numberInput = useNumberInput({
		precision: 2,
		step: ".5",
		min: 0,
		inputmode: "numeric",
		inputMode: "numeric",
		pattern: "[0-9]*",
		allowMouseWheel: true,
		format: (value) => formatValue(value, 1, "number"),
	});

	return (
		<Flex
			onMouseEnter={
				deviceType !== "mobile" ? setMouseHovering : () => false
			}
			onMouseLeave={
				deviceType !== "mobile" ? setHovering.off : () => false
			}
			position="relative"
			tabIndex="-1"
		>
			<Tooltip
				label="Over 8 Hours have been Charged"
				isDisabled={!isOverTime}
				tabIndex="-1"
			>
				<Input
					{...numberInput}
					type="number"
					value={
						parseFloat(localVal) === 0.0
							? ""
							: formatValue(localVal, 1, "number")
					}
					// boxShadow={"0px 0px 0px 0px transparent"}
					borderBottomWidth={2}
					_hover={{
						borderBottomColor: inputAvailable
							? "teal.600"
							: "red.600",
					}}
					variant="flushed"
					fontWeight="semibold"
					bg="white"
					// rounded="md"

					isDisabled={inputDisabled}
					cursor={inputDisabled ? "not-allowed" : "pointer"}
					// onClick={(ev) => {
					// 	ev.stopPropagation();
					// }}
					// maxW="50px"
					// onChange={changeHours}
					onChange={(valueString) => {
						if (valueString !== localVal) {
							setLocalVal(valueString?.toString());
						}
					}}
					onBlur={uploadHours}
					size="md"
					textAlign={"center"}
					placeholder={"0.0"}
					color={isOverTime ? "red.600" : "gray.600"}
					focusBorderColor="teal.600"
					autoComplete="off"
				/>
			</Tooltip>

			<IconButton
				key={notesIcon}
				icon={<i className={notesIcon}></i>}
				color="gray.500"
				position="absolute"
				right={-4}
				bg="none"
				hidden={hasNotes ? !hasNotes : !isHovering}
				size="sm"
				variant="ghost"
				cursor={inputDisabled ? "not-allowed" : "pointer"}
				onClick={() => {
					openNotes(hourIndex);
				}}
				isDisabled={inputDisabled}
				tabIndex="-1"
			/>
		</Flex>
	);
}
